@tailwind base;
@tailwind components;
@tailwind utilities;

p{
    font-family: ProximaNova, arial, Helvetica Neue, sans-serif;
}

.black-overlay{
    background-color: #282c3fa3;
}

.image-overlay {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 50%, rgba(0, 0, 0, 0.76));
}